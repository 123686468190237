/* tslint:disable */
/* eslint-disable */
/**
 * Pomelo Backend Server
 * Pomelo Backend Server v1 API Documentation
 *
 * The version of the OpenAPI document: 1.0.5
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Cardholder agreement type which must be signed by the user to complete the application
 * @export
 */
export const CHAgreement = {
  Charge: "CHARGE",
  Revolving: "REVOLVING",
} as const
export type CHAgreement = (typeof CHAgreement)[keyof typeof CHAgreement]

export function CHAgreementToJSON(value?: CHAgreement | null): any {
  return value as any
}
