import { Button, HStack, Link, Stack, Txt, VStack } from "@pomebile/primitives"
import { ScreenFrame } from "../components/ScreenFrame"
import { ReviewScreens, OnboardingApplication } from "../onboardingModel"
import { IdentifierType, SsnOrItinOutcome } from "../api/webRoutes"
import { useState } from "react"
import { PencilSimpleIcon } from "@pomebile/design-system"
import { Divider } from "../components/Divider"
import { StickyBottom } from "../components/StickyBottom"

interface ReviewScreenProps {
  api: {
    submitSsnOrItin: (
      ssnOrItin: string,
      identifierType: IdentifierType,
    ) => Promise<SsnOrItinOutcome>
  }
  onReviewInfo: (info: ReviewScreens) => void
  application: OnboardingApplication
  onDone: (result: SsnOrItinOutcome) => void
}

interface ReviewCellProps {
  header: string
  description: string | React.ReactNode
  onEdit: () => void
  cta?: React.ReactNode
}

const ReviewCell = ({ header, description, cta, onEdit }: ReviewCellProps) => {
  const body = typeof description === "string" ? <Txt>{description}</Txt> : description

  return (
    <VStack>
      <HStack justifyContent="center" alignItems="center" width="auto" flex={1} gap="xs">
        <VStack flex={1} overflow="hidden" overflowWrap="break-word">
          <Txt variant="caption" color="text-caption">
            {header}
          </Txt>

          {body}
        </VStack>

        <HStack width="fitContent" gap="md" alignItems="center">
          {cta}
          <div onClick={onEdit} style={{ lineHeight: 0 }}>
            <PencilSimpleIcon width={24} height={24} />
          </div>
        </HStack>
      </HStack>
    </VStack>
  )
}

export function ReviewScreen({ api, application, onReviewInfo, onDone }: ReviewScreenProps) {
  const [isLoading, setIsLoading] = useState(false)
  const [hideSsn, setHideSsn] = useState(true)

  const handleSubmitInfo = async () => {
    setIsLoading(true)
    if (!application.ssn) throw new Error("Invalid ssn")

    const identifierType = application.ssn[0] !== "9" ? "SSN" : "ITIN"

    try {
      const res = await api.submitSsnOrItin(application.ssn, identifierType)

      onDone(res)
    } catch (err) {
      throw err
    } finally {
      setIsLoading(false)
    }
  }

  const formatSSN = (ssn: string): string => {
    if (hideSsn) {
      return "•••-••-••••"
    }

    const ssnStr = ssn.toString()
    if (ssnStr.length !== 9 || !/^\d{9}$/.test(ssnStr)) {
      return ""
    }
    return `${ssnStr.slice(0, 3)}-${ssnStr.slice(3, 5)}-${ssnStr.slice(5)}`
  }

  const formatAddress = (): React.ReactNode => {
    if (!application.address) return null

    return (
      <>
        <Txt>{application.address.addressLineOne}</Txt>
        {application.address.addressLineTwo && <Txt>{application.address.addressLineTwo}</Txt>}
        <Txt>{`${application.address.city}, ${application.address.state} ${
          application.address.zip ?? ""
        }`}</Txt>
      </>
    )
  }

  return (
    <ScreenFrame>
      <Stack gap="lg" height="full">
        <VStack gap="xs">
          <Txt variant="headline2">Review your information</Txt>
          <Txt>Accurate information is required for us to verify your identity.</Txt>
        </VStack>

        <VStack gap="md" padding="md" border={["thin", "border-main", "solid"]} borderRadius="md">
          <ReviewCell
            header="Legal name"
            description={`${application.personalInfo.firstName} ${application.personalInfo.lastName}`}
            onEdit={() => onReviewInfo("userInfo")}
          />
          <Divider />
          <ReviewCell
            header="Email"
            description={application.personalInfo.email}
            onEdit={() => onReviewInfo("userInfo")}
          />
          <Divider />
          <ReviewCell
            header="Home address"
            description={formatAddress()}
            onEdit={() => onReviewInfo("address")}
          />
          <Divider />
          <ReviewCell
            header="Date of birth"
            description={
              application.personalInfo.dateOfBirth?.toLocaleDateString("en-US", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
              }) ?? ""
            }
            onEdit={() => onReviewInfo("verifyIdentity")}
          />
          <Divider />
          <ReviewCell
            header="SSN or ITIN"
            description={formatSSN(application.ssn ?? "")}
            onEdit={() => onReviewInfo("verifyIdentity")}
            cta={
              <Link onClick={() => setHideSsn((prev) => !prev)}>
                <Txt variant="button2" color="text-brand">
                  {hideSsn ? "Show" : "Hide"}
                </Txt>
              </Link>
            }
          />
        </VStack>
      </Stack>
      <StickyBottom>
        <Button state={isLoading ? "loading" : "active"} onClick={handleSubmitInfo}>
          Done
        </Button>
      </StickyBottom>
    </ScreenFrame>
  )
}
