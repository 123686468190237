import { Avatar, Txt, VStack } from "@pomebile/primitives"
import { ScreenFrame } from "../components/ScreenFrame"
import { AlertIcon } from "@pomebile/design-system"
import { useEffect } from "react"

export function ExistingAccountError() {
  useEffect(() => {
    // Clear session storage in case there's an issue with the cached tokens
    sessionStorage.clear()
  }, [])

  return (
    <ScreenFrame>
      <VStack justifyContent="space-between" height="full">
        <VStack gap="md" justifyContent="center" alignItems="center" height="full">
          <Avatar
            size="lg"
            variant="error"
            svg={<AlertIcon width={34} height={34} fill="icon-error" />}
          />
          <VStack gap="xs">
            <Txt variant="headline2" as="h1" textAlign="center">
              We're sorry, but we’re unable to approve your application due to a prior account you
              had with Pomelo.
            </Txt>
          </VStack>
        </VStack>
      </VStack>
    </ScreenFrame>
  )
}
