/* tslint:disable */
/* eslint-disable */
/**
 * Pomelo Backend Server
 * Pomelo Backend Server v1 API Documentation
 *
 * The version of the OpenAPI document: 1.0.5
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * List of documents that the applicant is signing
 * @export
 */
export const DocumentType = {
  PomeloTermsV1: "POMELO_TERMS_V1",
  CoastalTermsV1: "COASTAL_TERMS_V1",
  EsignV1: "ESIGN_V1",
  CardholderAgreementV1: "CARDHOLDER_AGREEMENT_V1",
  PomeloTerms: "POMELO_TERMS",
  CoastalTerms: "COASTAL_TERMS",
  Esign: "ESIGN",
  CardholderAgreement: "CARDHOLDER_AGREEMENT",
  PromoTerms: "PROMO_TERMS",
  MtpPromoTerms: "MTP_PROMO_TERMS",
  RevolvingChAgreement: "REVOLVING_CH_AGREEMENT",
} as const
export type DocumentType = (typeof DocumentType)[keyof typeof DocumentType]

export function DocumentTypeToJSON(value?: DocumentType | null): any {
  return value as any
}
