import { useCallback, useState } from "react"
import { usAutocompletePro, usStreet } from "smartystreets-javascript-sdk"

import { debounce } from "../helpers/timing"
import { findAddress, verifyAddress } from "../helpers/address"

export type Suggestion = usAutocompletePro.Suggestion
export type Candidate = usStreet.Candidate

export type SuggestionAddress = {
  street: string
  secondary?: string
  city: string
  state: string
  zipCode: string
}

type SearchAddressResponse =
  | {
      result: "ok"
      address: SuggestionAddress
    }
  | {
      result: "no_match"
    }
  | {
      result: "error"
      message: string
    }

type UseAddressSuggestionsHook = () => {
  suggestions: Suggestion[]
  addressSuggestion: SuggestionAddress | undefined
  fetchSuggestions: (address: string) => void
  searchAddress: (address: SuggestionAddress) => Promise<SearchAddressResponse>
  areAddressesIdentical: (
    address: SuggestionAddress,
    suggestedAddress: SuggestionAddress,
  ) => boolean
  clearSuggestions: () => void
}

export const useAddressSuggestions: UseAddressSuggestionsHook = () => {
  const [suggestions, setSuggestions] = useState<Suggestion[]>([])
  const [addressSuggestion, setAddressSuggestion] = useState<SuggestionAddress>()

  const fetchSuggestions = useCallback(
    debounce(async (address: string) => {
      try {
        // We are only supporting US addresses for now
        const response = await findAddress(address)

        if (response?.result?.length > 0) {
          setSuggestions(response.result)
        } else {
          setSuggestions([])
        }
      } catch (err) {
        console.log(err)
      }
    }, 300),
    [],
  )

  const clearSuggestions = useCallback(() => {
    setSuggestions([])
    setAddressSuggestion(undefined)
  }, [])

  const searchAddress = async ({
    street,
    secondary,
    city,
    state,
    zipCode,
  }: SuggestionAddress): Promise<SearchAddressResponse> => {
    try {
      const response = await verifyAddress({
        street,
        secondary,
        city,
        state,
        zipCode,
      })

      if (response.result === "ok") {
        const suggestedAddress = response.address
        const primaryNumber = suggestedAddress.components.primaryNumber
        const streetName = suggestedAddress.components.streetName
        const streetSuffix = suggestedAddress.components.streetSuffix
        const streetPreDirection = suggestedAddress.components.streetPredirection
        const streetPostDirection = suggestedAddress.components.streetPostdirection
        const secondaryDesignator = suggestedAddress.components.secondaryDesignator
        const secondaryNumber = suggestedAddress.components.secondaryNumber

        const street = [
          primaryNumber,
          streetPreDirection,
          streetName,
          streetSuffix,
          streetPostDirection,
        ]
          .filter(Boolean)
          .join(" ")

        const secondary = [secondaryDesignator, secondaryNumber].filter(Boolean).join(" ")

        const address: SuggestionAddress = {
          street,
          secondary,
          city: suggestedAddress.components.defaultCityName,
          zipCode: suggestedAddress.components.zipCode,
          state: suggestedAddress.components.state,
        }

        setAddressSuggestion(address)
        return {
          result: "ok",
          address: address,
        }
      }

      setAddressSuggestion(undefined)
      return response
    } catch (err) {
      if (err instanceof Error) {
        return { result: "error", message: err.message }
      }
      return { result: "error", message: "Search Address Error" }
    }
  }

  const areAddressesIdentical = (
    address: SuggestionAddress,
    suggestedAddress: SuggestionAddress,
  ) => {
    return (
      address.street === suggestedAddress.street &&
      (address.secondary ?? "") === (suggestedAddress.secondary ?? "") &&
      address.city === suggestedAddress.city &&
      address.state === suggestedAddress.state &&
      address.zipCode === suggestedAddress.zipCode
    )
  }

  return {
    suggestions,
    addressSuggestion,
    clearSuggestions,
    fetchSuggestions,
    searchAddress,
    areAddressesIdentical,
  }
}
