import { MtpRatePromoDto } from "@pomebile/pomelo-service-api"
import { MtpRatePromo } from "../api/webRoutes"

export const mapUserMtpRatePromo = (
  userMtpRatePromo?: MtpRatePromoDto,
): MtpRatePromo | undefined => {
  if (!userMtpRatePromo || !userMtpRatePromo.limit) {
    return undefined
  }

  return {
    id: userMtpRatePromo.id,
    rate: userMtpRatePromo.rate,
    limit: userMtpRatePromo.limit,
  }
}
