/* tslint:disable */
/* eslint-disable */
/**
 * Pomelo Backend Server
 * Pomelo Backend Server v1 API Documentation
 *
 * The version of the OpenAPI document: 1.0.5
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type {
  AddAddressRequest,
  ApiError,
  AuthResponse,
  CardColorSelection,
  CardColorsResponse,
  CardConfiguration,
  DeclineOfferRequest,
  KycResponse,
  SignUpRequest,
  SubmitCredit200Response,
  SubmitCreditRequest,
  SubmitSsnNineRequest,
  SubmitSsnOrItinRequest,
  UserDto,
  UserResponse,
} from "../models"

export const addSignUp = {
  url: "/v1/addSignUp",
  method: "POST",
  contentType: "application/json",
  meta: {} as {
    req: SignUpRequest
    res: AuthResponse
  },
} as const

export const declineOffer = {
  url: "/v1/declineOffer",
  method: "PUT",
  contentType: "application/json",
  meta: {} as {
    req: DeclineOfferRequest
    res: UserResponse
  },
} as const

export const getAvailableCardConfigurations = {
  url: "/v1/cardConfigurations",
  method: "GET",
  meta: {} as {
    queryParams: {
      userIdent: string
    }
    res: CardColorsResponse
  },
} as const

export const getCardConfigs = {
  url: "/v1/cards",
  method: "GET",
  meta: {} as {
    res: Array<CardConfiguration>
  },
} as const

export const putCardConfiguration = {
  url: "/v1/selectCardColor",
  method: "PUT",
  contentType: "application/json",
  meta: {} as {
    req: CardColorSelection
    res: UserDto
  },
} as const

export const submitCredit = {
  url: "/v1/submitCredit",
  method: "PUT",
  contentType: "application/json",
  meta: {} as {
    req: SubmitCreditRequest
    res: SubmitCredit200Response
  },
} as const

export const submitSsnNine = {
  url: "/v1/submitSsnNine",
  method: "PUT",
  contentType: "application/json",
  meta: {} as {
    req: SubmitSsnNineRequest
    res: KycResponse
  },
} as const

export const submitSsnOrItin = {
  url: "/v1/submitSsnOrItin",
  method: "PUT",
  contentType: "application/json",
  meta: {} as {
    req: SubmitSsnOrItinRequest
    res: KycResponse
  },
} as const

export const updateUserAddress = {
  url: "/v1/addAddress",
  method: "PUT",
  contentType: "application/json",
  meta: {} as {
    req: AddAddressRequest
    res: UserResponse
  },
} as const
