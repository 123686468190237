import { Avatar, Link, Txt, VStack } from "@pomebile/primitives"
import { ScreenFrame } from "../components/ScreenFrame"
import { AlertIcon } from "@pomebile/design-system"

export function CreditFrozenMaxAttemptsError() {
  return (
    <ScreenFrame>
      <VStack justifyContent="space-between" height="full">
        <VStack gap="md" justifyContent="center" alignItems="center" height="full">
          <Avatar
            size="lg"
            variant="error"
            svg={<AlertIcon width={34} height={34} fill="icon-error" />}
          />
          <VStack gap="xs">
            <Txt variant="headline2" as="h1" textAlign="center">
              Credit check limit reached
            </Txt>
            <Txt variant="body1" textAlign="center">
              {" "}
              <Link url="https://www.pomelo.com/support" target="_blank">
                Contact Support
              </Link>
            </Txt>
          </VStack>
        </VStack>
      </VStack>
    </ScreenFrame>
  )
}
