import { CHAgreement } from "@pomebile/pomelo-service-api"
import { Link, Txt } from "@pomebile/primitives"

interface Props {
  cardholderAgreement: CHAgreement
}

const getCardholderAgreementUrl = (cardholderAgreement: CHAgreement): string => {
  switch (cardholderAgreement) {
    case CHAgreement.Revolving: // TODO: Add URL when we have revolving CHA ready
    case CHAgreement.Charge:
      return "https://www.pomelo.com/legal/CHAgreement_Pomelo_v12.17.21_Clean-CCB-Approved-12.21.21.pdf"
  }
}

export const CardholderAgreementDisclaimer = ({ cardholderAgreement }: Props) => (
  <Txt variant="caption">
    By accepting the offer, you agree to the{" "}
    <Link
      color="primary"
      decoration="underline"
      url={getCardholderAgreementUrl(cardholderAgreement)}
      target="_blank"
    >
      Cardholder Agreement
    </Link>{" "}
    and authorize Pomelo to do a full credit inquiry, which may affect your credit score.
  </Txt>
)
