import { HStack, Link, Txt, VStack } from "@pomebile/primitives"
import { CrossIcon, IdentifierTag } from "@pomebile/design-system"
import { Dialog } from "../../components/Dialog"
import { CardPreview } from "../../components/CardPreview/CardPreview"
import { labelStyles, noWrap } from "./RatesAndFeesDialog.css"
import { DialogHeader } from "../../components/Dialog/DialogHeader"

export interface RatesAndFeesDialogComponentProps {
  isOpen: boolean
  onClose: () => void
  onOpenTermsAndConditions: () => void
  creditLimit: string
}

const DescriptionRow = ({ title, description }: { title: string; description: string }) => {
  return (
    <HStack
      justifyContent="space-between"
      alignItems="flex-start"
      border={{ color: "border-main", widths: { bottom: "thin" } }}
      padding={{ y: "sm" }}
      gap="xl"
    >
      <p className={`${labelStyles} ${noWrap}`}>{title}</p>
      <Txt variant="body2" textAlign="right">
        {description}
      </Txt>
    </HStack>
  )
}

export const RatesAndFeesDialogComponent = ({
  isOpen,
  onClose,
  onOpenTermsAndConditions,
  creditLimit,
}: RatesAndFeesDialogComponentProps) => {
  return (
    <Dialog open={isOpen} onClose={onClose}>
      <DialogHeader>
        <HStack gap="xs" alignItems="center">
          <Txt variant="subtitle2">Pomelo Mastercard®</Txt>
        </HStack>
        <HStack width="auto" justifyContent="flex-end" alignItems="center">
          <Link onClick={onClose}>
            {/* Link adds unecessary height so we start at flex end */}
            <VStack justifyContent="flex-end">
              <CrossIcon width={24} height={24} />
            </VStack>
          </Link>
        </HStack>
      </DialogHeader>
      <VStack padding="xl" gap="xl">
        <VStack justifyContent="center" alignItems="center">
          <CardPreview width={179.93} height={132.01} fill="icon-brand" />
          <IdentifierTag variant="primary">No APR</IdentifierTag>
        </VStack>
        <VStack>
          <DescriptionRow
            title="Payment Schedule"
            description="Statement balance due in full every month"
          />
          <DescriptionRow title="Credit Limit" description={creditLimit} />
          <DescriptionRow title="APR (Annual Percentage Rate)" description="None" />
          <DescriptionRow title="Interest Charges" description="None" />
          <DescriptionRow title="Annual Fees" description="None" />
          <DescriptionRow title="Late Fees" description="$29 if one month past due date" />
        </VStack>
        <VStack justifyContent="center" alignItems="center">
          <Txt variant="caption" color="text-caption">
            Terms and conditions apply.
          </Txt>
          <Link color="neutral" decoration="underline" onClick={onOpenTermsAndConditions}>
            <Txt variant="caption" color="text-caption">
              Pomelo Account Card Holder Agreement
            </Txt>
          </Link>
        </VStack>
      </VStack>
    </Dialog>
  )
}
