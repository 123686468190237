/* tslint:disable */
/* eslint-disable */
/**
 * Pomelo Backend Server
 * Pomelo Backend Server v1 API Documentation
 *
 * The version of the OpenAPI document: 1.0.5
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type {
  UpdateEmailRequest,
  UpdateEmailResponse,
  UpdateNameRequest,
  UpsertAddressRequest,
  UpsertAddressResponse,
  UpsertDobRequest,
  UpsertDobResponse,
} from "../models"

export const updateEmail = {
  url: "/v2/sign_up/email",
  method: "PATCH",
  contentType: "application/json",
  meta: {} as {
    req: UpdateEmailRequest
    res: UpdateEmailResponse
  },
} as const

export const updateName = {
  url: "/v2/sign_up/name",
  method: "PATCH",
  contentType: "application/json",
  meta: {} as {
    req: UpdateNameRequest
    res: void
  },
} as const

export const upsertAddress = {
  url: "/v2/sign_up/address",
  method: "PUT",
  contentType: "application/json",
  meta: {} as {
    req: UpsertAddressRequest
    res: UpsertAddressResponse
  },
} as const

export const upsertDateOfBirth = {
  url: "/v2/sign_up/dob",
  method: "PUT",
  contentType: "application/json",
  meta: {} as {
    req: UpsertDobRequest
    res: UpsertDobResponse
  },
} as const
