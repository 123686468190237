import { Avatar, HStack, Txt, VStack } from "@pomebile/primitives"
import { SubmitButton } from "../components/Form/FormSubmitButton"
import { ScreenFrame } from "../components/ScreenFrame"
import { StickyBottom } from "../components/StickyBottom"
import { Link } from "@pomebile/primitives-web"
import { AlertIcon, CrossIcon, ExclamationIcon, VSpace } from "@pomebile/design-system"
import { CreditApplicationOutcome, SubmitCreditResponse } from "../api/webRoutes"
import { useLogging } from "../utils/logging"
import { SubmitStatus } from "../hooks/useSubmit"
import { useState } from "react"
import { Dialog } from "../components/Dialog"
import { DialogHeader } from "../components/Dialog/DialogHeader.tsx"

export interface CreditFrozenProps {
  api: {
    resubmitApplication: () => Promise<SubmitCreditResponse>
  }

  onDone: (outcome: CreditApplicationOutcome) => void
}

export function CreditFrozen({ api: { resubmitApplication }, onDone }: CreditFrozenProps) {
  const logging = useLogging()
  const [showFrozenCreditWarning, setShowFrozenCreditWarning] = useState(false)
  const [submitState, setSubmitState] = useState<SubmitStatus>("idle")
  const [creditStillFrozen, setCreditStillFrozen] = useState(false)

  const handleResubmitApplication = async () => {
    setSubmitState("submitting")
    setCreditStillFrozen(false)
    setShowFrozenCreditWarning(false)

    try {
      const res = await resubmitApplication()

      switch (res.tag) {
        case "frozen_credit":
          setCreditStillFrozen(true)
          break

        case "secured":
          onDone({
            tag: "approvedSecured",
            creditAppIdent: res.creditAppIdent,
            updatedMtpRatePromo: undefined,
            cardholderAgreement: res.cardholderAgreement,
          })
          break

        case "unsecured":
          onDone({
            tag: "approvedUnsecured",
            creditAppIdent: res.creditAppIdent,
            updatedMtpRatePromo: undefined,
            approvedLimit: res.approvedLimit,
            cardholderAgreement: res.cardholderAgreement,
          })
          break
        case "frozen_credit_last_warning":
          // We handle this state internally by showing a warning dialog
          setShowFrozenCreditWarning(true)
          break
        case "too_many_attempts":
          onDone({ tag: "frozenCreditMaxAttemptsError" })
          break

        default:
          onDone({ tag: "errorSubmittingApplication" })
          break
      }

      setSubmitState("done")
    } catch (error) {
      logging.logError(error)

      setSubmitState("submitErr")

      onDone({
        tag: "errorSubmittingApplication",
      })
    }
  }

  return (
    <ScreenFrame>
      <VStack gap="xl2" height="full" justifyContent="space-between">
        <VStack gap="xl2">
          <VStack alignItems="center" gap="xs">
            <Txt variant="headline2" as="h1" textAlign="center" color="text-default">
              Frozen Credit
            </Txt>
            <Txt textAlign="center">
              It looks like your credit is frozen. To submit your application for Pomelo, you'll
              need to contact TransUnion and lift the freeze.
            </Txt>
          </VStack>
          <VStack gap="md">
            <Txt variant="button2" textAlign="center">
              Here's how to temporarily lift your credit freeze
            </Txt>
            <VStack gap="md">
              <Txt variant="body2">
                1. Go to{" "}
                <Link url="https://www.transunion.com/credit-freeze" target="_blank">
                  https://www.transunion.com/credit-freeze
                </Link>
              </Txt>
              <Txt variant="body2">
                2. To unfreeze for a specific timeframe, sign in to your TransUnion account online
                and select “Temporarily Lift Freeze”
              </Txt>
              <Txt variant="body2">
                3. Enter the dates you want your credit report to be unfrozen.
              </Txt>
              <Txt variant="body2">
                4. TransUnion will automatically add a freeze back on your credit report at midnight
                on the end date.
              </Txt>
            </VStack>
          </VStack>
        </VStack>
        <StickyBottom>
          <VStack justifyContent="center">
            <Txt textAlign="center" variant="caption">
              When you've unfrozen your credit:
            </Txt>
          </VStack>
          <VSpace height="md" />
          {creditStillFrozen && (
            <VStack alignItems="center" padding={{ bottom: "sm" }}>
              <HStack gap="xs2" justifyContent="center">
                <ExclamationIcon width={14} height={14} fill="icon-error" />
                <Txt as="span" variant="caption" color="text-error">
                  It looks like your credit is still frozen.
                </Txt>
              </HStack>
            </VStack>
          )}
          <SubmitButton
            status={submitState}
            disabled={submitState === "submitting"}
            submit={handleResubmitApplication}
          >
            Continue to apply
          </SubmitButton>
        </StickyBottom>
      </VStack>
      <Dialog
        open={showFrozenCreditWarning}
        onClose={() => {
          setShowFrozenCreditWarning(false)
        }}
      >
        <DialogHeader>
          <HStack gap="xs" alignItems="center" />
          <HStack width="auto" justifyContent="flex-end" alignItems="center">
            <Link
              onClick={() => {
                setShowFrozenCreditWarning(false)
              }}
            >
              <VStack justifyContent="flex-end">
                <CrossIcon width={24} height={24} />
              </VStack>
            </Link>
          </HStack>
        </DialogHeader>
        <VStack padding="xl" gap="xl" justifyContent="space-between" height="full">
          <VStack gap="md" justifyContent="center" alignItems="center" height="full">
            <Avatar
              size="lg"
              variant="error"
              svg={<AlertIcon width={34} height={34} fill="icon-error" />}
            />
            <VStack gap="xs">
              <Txt variant="headline2" as="h1" textAlign="center">
                We’ve detected your credit is still frozen
              </Txt>
              <Txt variant="body1" textAlign="center">
                To finish your application for Pomelo, go to{" "}
                <Link url="https://www.transunion.com/credit-freeze" target="_blank">
                  https://www.transunion.com/credit-freeze
                </Link>{" "}
                and lift the freeze. Once lifted, allow up to one business day for the change to
                process in their system.
              </Txt>
              <Txt variant="body1" textAlign="center">
                For your protection, you have 1 more attempt to apply.
              </Txt>
            </VStack>
          </VStack>
          <StickyBottom>
            <VSpace height="md" />
            <SubmitButton
              status={submitState}
              disabled={submitState === "submitting"}
              submit={() => {
                setShowFrozenCreditWarning(false)
              }}
            >
              Close
            </SubmitButton>
          </StickyBottom>
        </VStack>
      </Dialog>
    </ScreenFrame>
  )
}
